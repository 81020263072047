import { CommonModule } from "@angular/common";
import { Component, forwardRef, ViewEncapsulation } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";

import { InputNumberModule } from "primeng/inputnumber";

@Component({
    selector: "ruts-year-input",
    standalone: true,
    imports: [CommonModule, InputNumberModule, FormsModule],
    templateUrl: "./year-input.component.html",
    styleUrls: ["./year-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => YearInputComponent),
            multi: true,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class YearInputComponent implements ControlValueAccessor {
    yearInput: number;
    onTouched: () => void;
    onChange: (x: number) => void;

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    writeValue(obj: number) {
        this.yearInput = +obj || new Date().getFullYear();
    }

    incrementYear() {
        this.yearInput += 1;
        this.onChange(this.yearInput);
    }

    decrementYear() {
        this.yearInput -= 1;
        this.onChange(this.yearInput);
    }
}
