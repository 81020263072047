import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

enum BREAKPOINTS {
    xs = 480,
    sm = 680,
    md = 768,
    lg = 992,
    xl = 1381,
}

@Injectable()
export class ScreenWidthDetectorService {
    private readonly _screenWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(1200);

    set width(value: number) {
        this._screenWidth$.next(value);
    }

    from(point: number | keyof typeof BREAKPOINTS, include: boolean = true): boolean {
        const currentScreenWidth: number = this._screenWidth$.getValue();

        switch (typeof point) {
            case "number":
                return include ? point <= currentScreenWidth : point < currentScreenWidth;
            case "string":
                return include
                    ? (BREAKPOINTS[point] || 0) <= currentScreenWidth
                    : (BREAKPOINTS[point] || 0) < currentScreenWidth;
            default:
                return false;
        }
    }

    to(point: number | keyof typeof BREAKPOINTS, include: boolean = false): boolean {
        const currentScreenWidth: number = this._screenWidth$.getValue();

        switch (typeof point) {
            case "number":
                return include ? point >= currentScreenWidth : point > currentScreenWidth;
            case "string":
                return include
                    ? (BREAKPOINTS[point] || 0) >= currentScreenWidth
                    : (BREAKPOINTS[point] || 0) > currentScreenWidth;
            default:
                return false;
        }
    }

    isDesktop(): boolean {
        return this.from("xl");
    }

    isXs(): boolean {
        return this.to("xs");
    }
}
