import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { Navigate } from "@ngxs/router-plugin";
import { Store } from "@ngxs/store";

import { PageName } from "@core/enums/defaults.enum";
import { IProceedRule, PROCEED_WITH_ERROR } from "@core/helpers/proceed-error.helper";
import { Nulled } from "@core/interfaces/core.interface";
import { ToastService } from "@core/services/toast.service";

import { AuthLoginPageStatus } from "../../../auth-page/enums/auth.enum";
import { AuthPageActions } from "../../../auth-page/store/auth.actions";
import { AuthPageState } from "../../../auth-page/store/auth.state";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private readonly _store: Store,
        private readonly _toastService: ToastService
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token: Nulled<string> = this._store.selectSnapshot(AuthPageState.getToken);

        if (token) {
            req = req.clone({ setHeaders: { token } });
        }

        return next
            .handle(req)
            .pipe(catchError((error: HttpErrorResponse) => this.handleError(req, error)));
    }

    private handleError(req: HttpRequest<any>, error: HttpErrorResponse): Observable<any> {
        const proceeds: IProceedRule | false =
            req.context.has(PROCEED_WITH_ERROR) && req.context.get(PROCEED_WITH_ERROR);

        if (proceeds && proceeds[error.status] !== undefined) {
            const handler = proceeds[error.status];

            if (handler === null) return of(error);

            return handler(error);
        }

        if (error.status >= HttpStatusCode.InternalServerError) {
            this._store.dispatch(new Navigate([PageName.Maintenance]));
            return throwError(() => error);
        }

        if (error.status === HttpStatusCode.UnprocessableEntity) {
            return throwError(() => error);
        }

        if (!error.error) {
            return this._store.dispatch(new AuthPageActions.Logout());
        }

        if (error.error.errors) {
            this._toastService.showErrorMessage(error.error.errors);

            return throwError(() => error);
        }

        if (error.status === HttpStatusCode.Unauthorized) {
            return this._store.dispatch(
                new AuthPageActions.Logout({ [AuthLoginPageStatus.SessionFailed]: true })
            );
        }

        if (error.status === HttpStatusCode.Forbidden) {
            return this._store.dispatch(
                new Navigate([PageName.Checker], {
                    [AuthLoginPageStatus.AccessDenied]: true,
                })
            );
        }

        // TODO: something strange, why can't be?
        if (!error.error) {
            return this._store.dispatch(new AuthPageActions.Logout());
        }

        if (error.status !== HttpStatusCode.Unauthorized) {
            this._toastService.showErrorMessage(error.message);
        }

        return throwError(() => error);
    }
}
