import { Pipe, PipeTransform } from "@angular/core";

import { SumHelper } from "../helpers/sum.helper";

@Pipe({
    name: "sum",
    standalone: true,
})
export class SumPipe implements PipeTransform {
    private _sumHelper = new SumHelper();

    transform(value: any, ...args: [("s" | "n" | "f")?, string?]): string | number | boolean {
        let type: keyof SumHelper = "toString",
            argToTypeMapper = {
                s: "toString",
                n: "toNumber",
                f: "isFloat",
            };

        if (args.length > 0 && args[0]) {
            type = argToTypeMapper[args[0]] as keyof SumHelper;

            if (!this._sumHelper[type]) {
                return (
                    'Передан неизвестный метод "' +
                    args[0] +
                    '". Допустимые: ' +
                    Object.keys(argToTypeMapper).join(", ") +
                    "."
                );
            }
        }

        return this._sumHelper[type](value, args[1]);
    }
}
