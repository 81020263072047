import { HttpContext, HttpContextToken, HttpErrorResponse, HttpEvent } from "@angular/common/http";

import { Observable } from "rxjs";

import { Nulled } from "@core/interfaces/option.interface";

export interface IProceedRule {
    [key: number]: Nulled<(e: HttpErrorResponse) => Observable<HttpEvent<any>>>;
}

export const PROCEED_WITH_ERROR = new HttpContextToken<IProceedRule>(() => ({}));

export function proceedWithError(handlers: IProceedRule) {
    return {
        context: new HttpContext().set(PROCEED_WITH_ERROR, handlers),
    };
}
