import { Component, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";

import { Observable } from "rxjs";

import { Select, Store } from "@ngxs/store";

import { SubscriberComponent } from "@core/classes/subscriber.class";
import { PageName } from "@core/enums/defaults.enum";
import { SearchFieldPlaceholderText, ToastMessageText } from "@core/enums/message.enum";
import { ToastService } from "@core/services/toast.service";

import { environment } from "../../environments/environment";
import { AuthLoginPageStatus } from "./enums/auth.enum";
import { IAuthLoginError } from "./interfaces/auth-page.interface";
import { AuthPageActions } from "./store/auth.actions";
import { AuthPageState } from "./store/auth.state";

@Component({
    selector: "ruts-auth",
    templateUrl: "./auth-login.component.html",
    styleUrls: ["./auth-login.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AuthLoginComponent extends SubscriberComponent {
    form: UntypedFormGroup;
    error: IAuthLoginError;
    pageName: typeof PageName = PageName;
    logoImg: string;
    readonly placeholder: typeof SearchFieldPlaceholderText = SearchFieldPlaceholderText;

    @Select(AuthPageState.getStatus) readonly isLoading$: Observable<boolean>;
    @Select(AuthPageState.getErrors) readonly errors$: Observable<string[]>;

    constructor(
        protected readonly _store: Store,
        private readonly _route: ActivatedRoute,
        private readonly _toastService: ToastService
    ) {
        super();
        this.logoImg = environment.logo;
    }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({
            login: new UntypedFormControl(null, [Validators.required]),
            password: new UntypedFormControl(null, [Validators.required]),
        });

        this.addSub([
            this._route.queryParams.subscribe((params: Params) => {
                if (params[AuthLoginPageStatus.AccessDenied]) {
                    this._toastService.showErrorMessage(ToastMessageText.AccessDenied);
                } else if (params[AuthLoginPageStatus.SessionFailed]) {
                    this._toastService.showErrorMessage(ToastMessageText.SessionFailed);
                }
            }),
            this.isLoading$.subscribe(() => {
                this.error = this._store.snapshot().auth?.loginError;
            }),
        ]);
    }

    onSubmit(): void {
        const { login, password } = this.form.value;
        this._store.dispatch(new AuthPageActions.Login({ login, password }));
    }
}
