import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "ruts-auth-layout",
    templateUrl: "./auth-layout.component.html",
    styleUrls: ["./auth-layout.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AuthLayoutComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
