import { Component, Renderer2, ViewEncapsulation } from "@angular/core";

import { Nulled } from "@core/interfaces/core.interface";

import { environment } from "../environments/environment";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    constructor(private readonly _renderer: Renderer2) {
        if (!environment.production) {
            this._renderer.addClass(document.body, "test-environment");
        }

        let favIcon: Nulled<HTMLLinkElement> = document.querySelector("#favIcon");
        if (favIcon && environment?.faviconPath) {
            favIcon.href = environment.faviconPath;
        }
    }
}
