<div class="ruts-auth">
    <i class="ruts-icon icon-logo" [routerLink]="'/'"></i>

    <div class="auth-form">
        <h2>Восстановление пароля</h2>
        <form
            *ngIf="!restoringPassFromMail; else restore"
            [formGroup]="mainForm"
            (ngSubmit)="sendEmail()">
            <div class="restore-password_description" *ngIf="recoveryInstructionsSent$ | async">
                Инструкция по восстановлению пароля отправлена на адрес электронной почты, указанный
                при регистрации
            </div>

            <div
                class="auth-error"
                *ngIf="mainForm.get('email')?.invalid && mainForm.get('email')?.touched">
                <p *ngIf="mainForm.get('email')?.hasError('required')">
                    Email не должен быть пустым
                </p>
                <p *ngIf="mainForm.get('email')?.hasError('email')">
                    Введен неверный формат <br />
                    электронной почты
                </p>
            </div>

            <input
                formControlName="email"
                pInputText
                type="email"
                [placeholder]="placeholder.YourEmail" />

            <button
                label="Восстановить"
                pButton
                type="submit"
                [disabled]="mainForm.invalid || mainForm.disabled || (isLoading$ | async)"></button>
        </form>

        <ng-template #restore>
            <form [formGroup]="formRestore" (ngSubmit)="restorePassword()">
                <p-password
                    formControlName="password"
                    [placeholder]="placeholder.EnterPassword"
                    [feedback]="false"
                    [ngClass]="{
                        'invalid-password':
                            formRestore.get('password')?.dirty &&
                            !formRestore.get('password')?.value
                    }"
                    [toggleMask]="true" />

                <p-password
                    formControlName="confirmPassword"
                    [placeholder]="placeholder.ConfirmPassword"
                    [feedback]="false"
                    [ngClass]="{
                        'invalid-password':
                            formRestore.get('confirmPassword')?.dirty &&
                            !formRestore.get('confirmPassword')?.value
                    }"
                    [toggleMask]="true" />

                <button
                    label="Восстановить"
                    pButton
                    type="submit"
                    [disabled]="
                        formRestore.invalid ||
                        formRestore.disabled ||
                        (isLoading$ | async) ||
                        formRestore.get('password')?.value !==
                            formRestore.get('confirmPassword')?.value
                    "></button>
            </form>
        </ng-template>
    </div>

    <div class="auth-navigation-block">
        <a [routerLink]="'/'">Вернуться назад</a>
    </div>
</div>
