<aside class="ruts-menu h-full">
    <div class="menu_logo">
        <i class="ruts-icon" [ngClass]="logoImg"></i>
    </div>

    <div class="menu-tochno">
        <p>
            <i class="pi pi-clock"></i>
            Сдача уточненок:
        </p>

        <div class="menu-tochno_date">
            <ng-container *ngIf="!(isLoading$ | async) && (user$ | async) as currentUser; else emptyTime">
                <span>{{ currentUser.tochnoTime | date: "dd.MM" }}</span>
                <span>{{ currentUser.tochnoTime | date: "HH:mm" }} (МСК)</span>
            </ng-container>

            <ng-template #emptyTime>
                <p-skeleton></p-skeleton>
            </ng-template>
        </div>
    </div>

    <button
        class="header_burger"
        icon="pi pi-times"
        iconPos="left"
        pButton
        type="button"
        *ngIf="!swd.isDesktop()"
        (click)="closeMenu()"></button>

    <ng-container *ngIf="(user$ | async) && !(isLoading$ | async); else empty">
        <p-menu [model]="(menuItems$ | async) ?? []">
        </p-menu>
    </ng-container>

    <ng-template #empty>
        <div class="menu-skeleton">
            <p-skeleton *ngFor="let item of (menuItems$ | async) ?? [] | slice: 0 : 7"></p-skeleton>
        </div>
    </ng-template>

    <div class="menu_copyright">
        <p>{{ menuCopyright }} ©</p>
    </div>
</aside>
