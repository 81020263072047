import { MenuItem } from "primeng/api";

import { PAGE_TITLE } from "@core/constants/core.constants";
import { PageName } from "@core/enums/defaults.enum";

export const getMenuModel = (
    isAdmin: boolean,
    partnersAvailable: boolean,
    isInexperienced: boolean,
    contact: string
): MenuItem[] => [
    {
        icon: "breach",
        label: PAGE_TITLE.breach,
        routerLink: ["/" + PageName.Breach],
        visible: !isInexperienced,
    },
    {
        icon: "config",
        label: "Конфигуратор",
        routerLink: ["/" + PageName.Chains],
        visible: isAdmin,
    },
    {
        icon: "thunder",
        label: "Генератор",
        routerLink: ["/" + PageName.Empty],
        visible: isAdmin,
    },
    {
        icon: "checker",
        label: "Чекер",
        routerLink: ["/" + PageName.Checker],
        visible: isAdmin || !isInexperienced,
    },
    {
        icon: "pencil",
        label: PAGE_TITLE.control,
        routerLink: ["/" + PageName.Control],
        visible: isAdmin || !isInexperienced,
    },
    {
        icon: "limit",
        label: "Лимиты",
        routerLink: ["/" + PageName.Empty],
        visible: isAdmin,
    },
    {
        icon: "rub",
        label: PAGE_TITLE.invoice,
        routerLink: ["/" + PageName.Invoice],
        visible: isAdmin || !isInexperienced,
    },
    {
        icon: "special",
        label: "Спецрежим",
        routerLink: ["/" + PageName.Empty],
        visible: isAdmin,
    },
    {
        icon: "books",
        label: PAGE_TITLE.books,
        routerLink: ["/" + PageName.Books],
        visible: isAdmin,
    },
    {
        icon: "dressing",
        label: PAGE_TITLE.dressing,
        routerLink: [ `/${PageName.Dressing}`],
        visible: isAdmin || !isInexperienced,
        routerLinkActiveOptions: { exact: false },
    },
    {
        icon: "docs",
        label: PAGE_TITLE.documents,
        routerLink: ["/" + PageName.Documents],
    },
    {
        icon: "users",
        label: PAGE_TITLE.subPartners,
        routerLink: ["/" + PageName.SubPartners],
        visible: !isAdmin && !isInexperienced && partnersAvailable,
    },
    {
        icon: "users",
        label: PAGE_TITLE.users,
        routerLink: ["/" + PageName.Users],
        visible: isAdmin,
    },
    {
        icon: "self-test",
        label: "Самотестирование",
        routerLink: ["/" + PageName.Empty],
        visible: isAdmin,
    },
    {
        icon: "contact",
        label: "Связаться с нами",
        visible: !!contact,
        command: () => {
            window.open(`https://telegram.me/${contact}`, "_blank");
        },
    },
];
