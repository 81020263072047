import { ViewEncapsulation, OnInit, Component, Input, Type } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

import { MenuItem } from "primeng/api";

import { PAGE_TITLE } from "@core/constants/core.constants";
import { PageName } from "@core/enums/defaults.enum";

@Component({
    selector: "ruts-content-header",
    templateUrl: "./content-header.component.html",
    styleUrls: ["./content-header.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ContentHeaderComponent implements OnInit {
    @Input() title: string | null;
    @Input() sticky: boolean = true;
    @Input() withBreadcrumb: boolean;
    @Input() breadcrumbItems: MenuItem[];
    @Input() topContentComponent: Type<any>;

    public isBlockFixed: boolean;

    constructor(
        private readonly _titleService: Title,
        private readonly _router: Router
    ) {}

    ngOnInit(): void {
        const url: string[] = this._router.url.split("?")[0].split("/");
        const currentUrl: string = url[url.length - 1];
        const title: string = this.title ? this.title : PAGE_TITLE[currentUrl as PageName];
        this.title = title;
        this._titleService.setTitle(title);
    }

    toggleStickyBlock(value: boolean): void {
        this.isBlockFixed = value;
    }
}
