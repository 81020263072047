import { NgIf, NgStyle } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
    selector: "ruts-progressbar",
    styleUrls: ["./progressbar.component.scss"],
    template: `
        <ng-container *ngIf="isNew; else old">
            <div class="progressbar" [ngStyle]="style"></div>
        </ng-container>

        <ng-template #old>
            <div class="c-page"><div class="progressbar" [ngStyle]="style"></div></div>
        </ng-template>
    `,
    standalone: true,
    imports: [NgIf, NgStyle],
})
export class ProgressbarComponent {
    @Input() style: { [key: string]: any };
    @Input() isNew: boolean = false;
}
