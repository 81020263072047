import { Injectable } from "@angular/core";

import { MessageService } from "primeng/api";

import { ToastMessageText } from "@core/enums/message.enum";

@Injectable({ providedIn: "root" })
export class ToastService {
    constructor(public _messageService: MessageService) {}

    showSuccessMessage(text?: string) {
        this._messageService.add({
            key: "mainToast",
            severity: "success",
            detail: text ? text : ToastMessageText.SucceededDataUpdate,
            life: 5000,
        });
    }

    showErrorMessage(text?: string) {
        this._messageService.add({
            key: "mainToast",
            severity: "error",
            detail: text ? text : ToastMessageText.ErrorUnknown,
            life: 5000,
        });
    }

    showInfoMessage(text?: string) {
        this._messageService.add({
            key: "mainToast",
            severity: "info",
            detail: text ? text : ToastMessageText.InfoUnknown,
            life: 5000,
        });
    }

    showWarningMessage(text?: string) {
        this._messageService.add({
            key: "mainToast",
            severity: "warn",
            detail: text ? text : ToastMessageText.InfoUnknown,
            life: 5000,
        });
    }
}
