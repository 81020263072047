import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

import { NgxsModule } from "@ngxs/store";

import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { PasswordModule } from "primeng/password";

import { PageName } from "@core/enums/defaults.enum";

import { AuthLoginComponent } from "./auth-login.component";
import { AuthInviteComponent } from "./components/auth-invite/auth-invite.component";
import { AuthRegistrationComponent } from "./components/auth-registration/auth-registration.component";
import { AuthRestorePasswordComponent } from "./components/auth-restore-password/auth-restore-password.component";
import { AuthService } from "./services/auth.service";
import { AuthPageState } from "./store/auth.state";

const routes: Routes = [
    {
        path: "",
        component: AuthLoginComponent,
    },
    {
        path: PageName.AuthInvites,
        component: AuthInviteComponent,
    },
    {
        path: PageName.RestorePassword,
        component: AuthRestorePasswordComponent,
    },
    {
        path: PageName.Registration,
        component: AuthRegistrationComponent,
    },
];

@NgModule({
    declarations: [
        AuthLoginComponent,
        AuthInviteComponent,
        AuthRestorePasswordComponent,
        AuthRegistrationComponent,
    ],
    imports: [
        CommonModule,
        InputTextModule,
        ButtonModule,
        FormsModule,
        PasswordModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        NgxsModule.forFeature([AuthPageState]),
    ],
    exports: [AuthLoginComponent, RouterModule],
    providers: [AuthService],
})
export class AuthModule {}
