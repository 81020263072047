import { LocaleSettings } from "primeng/calendar";

import { PageName, TariffType } from "@core/enums/defaults.enum";

export const TOKEN_STORAGE_KEY = "ruts_access_token";
export const PERIOD_STORAGE_KEY = "_bp";
export const STORAGE_DATA_SEPARATOR = "@!";
export const DATE_PATTERN = "(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}";

export const SPECIAL_ACCOUNT = "bumzone_master";

export const PAGE_TITLE: Record<PageName, string> = {
    [PageName.Auth]: "Авторизация",
    [PageName.Registration]: "Регистрация",
    [PageName.AuthInvites]: "Авторизация. Инвайт",
    [PageName.RestorePassword]: "Восстановить пароль",
    [PageName.Users]: "Пользователи",
    [PageName.Invites]: "Инвайты",
    [PageName.Aquariums]: "Аквариумы и уточненки",
    [PageName.External]: "Внешние проекты и фирмы",
    [PageName.Books]: "Книги",
    [PageName.Checker]: "Чекер. Проверка и загрузка заявок",
    [PageName.Control]: "Контроль",
    [PageName.SubPartners]: "Суб-партнеры",
    [PageName.Dressing]: "Примерочная",
    [PageName.DressingAdminMain]: "Примерочная. Основные",
    [PageName.DressingAdminTochno]: "Примерочная. Уточненки",
    [PageName.Documents]: "Документы",
    [PageName.Invoice]: "Счета",
    [PageName.Breach]: "Разрывы",
    [PageName.BreachCancellation]: "Аннулированные",
    [PageName.BreachLossOfControl]: "Потеря контроля",
    [PageName.BreachReplacement]: "Список замен",
    [PageName.PersonalSubstitutions]: "Индивидуальные замены",
    [PageName.BreachLostBack]: "Потеря контроля (ждем новую ЭП)",
    [PageName.BreachPro]: "Настроить доступ к Pro-версии",
    [PageName.BreachInBottom]: "Разрыв в низах",
    [PageName.Chains]: "Конфигуратор",
    [PageName.Archive]: "Архив счетов",
    [PageName.NotFound]: "Страница не найдена",
    [PageName.Empty]: "Пустая страница",
    [PageName.Maintenance]: "Технические работы",
};
export const TARIFF_TOOLTIP: Record<TariffType, string> = {
    [TariffType.Elite]: "",
    [TariffType.Premium]:
        "<b>PREMIUM:</b><br />Схема с удлиненной цепочкой из четырех компаний, включающей отлежавшийся вычет и перевод источника НДС в непроверяемый период. Нижние звенья используются только один раз.",
    [TariffType.Standard]:
        "<b>STANDARD:</b><br />Схема с технической цепочкой из двух компаний, прикрытых посредниками для маскировки источника НДС.",
};

export const PRIME_CALENDAR_CONFIG: LocaleSettings = {
    dateFormat: "dd.mm.yy",
    dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    monthNamesShort: [
        "Янв",
        "Фев",
        "Мар",
        "Апр",
        "Май",
        "Июн",
        "Июл",
        "Авг",
        "Сен",
        "Окт",
        "Ноя",
        "Дек",
    ],
    monthNames: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
    ],
};

export const pageRoles: { [key in PageName]?: string[] } = {
    [PageName.Breach]: ["!isInexperienced"],
    [PageName.Books]: ["isAdmin"],
    [PageName.Chains]: ["isAdmin"],
    [PageName.Dressing]: ["isAdmin", "!isInexperienced"],
    [PageName.Empty]: ["isAdmin"],
    [PageName.Invoice]: ["isAdmin", "!isInexperienced"],
    [PageName.SubPartners]: ["!isAdmin && !isInexperienced && partnersAvailable"],
    [PageName.Users]: ["isAdmin"],
};

export const PERIODIC_URL_REGEX = new RegExp(/\/\d\/\d{4}$/, "g");

export const PAGE_SIZE = 500;
