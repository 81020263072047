export class SumHelper {
    toString(number: number, placeholder: string = "0.00"): string {
        if (!number) {
            return placeholder;
        }

        return new Intl.NumberFormat("ru-RU", {
            minimumFractionDigits: 2,
        }).format(number);
    }

    toNumber(str: string | number): number {
        if (typeof str === "number") {
            return str;
        }

        let result = str.replace(",", ".").replace(/\s/g, "");

        return parseFloat(result);
    }

    isFloat(num: number): boolean {
        return /\./g.test(num.toString());
    }
}
