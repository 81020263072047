<p-radioButton
    class="first"
    label="1"
    name="quarter"
    [value]="1"
    [class.active]="quarter === 1"
    [ngModel]="quarter"
    (click)="onQuarterChange(1)" />
<p-radioButton
    class="middle"
    label="2"
    name="quarter"
    [value]="2"
    [class.active]="quarter === 2"
    [ngModel]="quarter"
    (click)="onQuarterChange(2)" />
<p-radioButton
    class="middle"
    label="3"
    name="quarter"
    [value]="3"
    [class.active]="quarter === 3"
    [ngModel]="quarter"
    (click)="onQuarterChange(3)" />
<p-radioButton
    class="last"
    label="4"
    name="quarter"
    [value]="4"
    [class.active]="quarter === 4"
    [ngModel]="quarter"
    (click)="onQuarterChange(4)" />
