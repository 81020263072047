import { inject } from "@angular/core";
import { CanActivateFn, Router, UrlMatcher } from "@angular/router";

import { combineLatest, of, switchMap } from "rxjs";
import { filter, map } from "rxjs/operators";

import { Store } from "@ngxs/store";

import { PERIODIC_URL_REGEX } from "@core/constants/core.constants";
import { PageName, userRoleKey } from "@core/enums/defaults.enum";
import { UserRole } from "@core/interfaces/core.interface";
import { CoreService } from "@core/services/core.service";
import { CoreState } from "@core/store/core.state";

import { AuthPageState } from "../../auth-page/store/auth.state";

export const canAuthenticate: CanActivateFn = () => !inject(CoreService).hasAuthToken();
export const isAuthenticated: CanActivateFn = () => {
    const store = inject(Store);
    const router = inject(Router);

    return combineLatest([
        store.select(AuthPageState.isAuthenticated),
        store.select(CoreState.getUser).pipe(map(Boolean)),
    ]).pipe(
        switchMap(([isAuthenticated, hasUserData]) =>
            !isAuthenticated
                ? of(router.parseUrl(PageName.Auth))
                :
                of(hasUserData).pipe(filter(Boolean))

        )
    );
};
export const periodicUrlMatcher: UrlMatcher = segments => {
    const path = segments.map(segment => segment.path).join("/");
    const hasPeriod = Boolean(path.match(PERIODIC_URL_REGEX));

    if (hasPeriod) {
        const [quarter, year] = segments.slice(-2);

        return {
            consumed: segments,
            posParams: {
                quarter,
                year,
            },
        };
    }

    return null;
};
export const isMaintenanceMode: CanActivateFn = () => inject(CoreService).healthCheck();

export const isAvailableRoleSimple = (roles: UserRole[]) => {
    return () => {
        const store = inject(Store);
        const currentUser = store.selectSnapshot(CoreState.getUser);

        if (currentUser) {
            return roles.some(role => currentUser[userRoleKey[role]]);
        }

        return null;
    };
};
