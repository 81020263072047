<div class="flex h-full">
    <ruts-menu class="flex flex-column h-full" style="z-index: 10003" />

    <div class="flex flex-column h-full w-full">
        <ruts-header style="z-index: 10002" />

        <main id="main" class="content h-full">
            <ng-container *ngIf="(currentUser$ | async) && !(contentUpdating$ | async); else empty">
                <router-outlet />
            </ng-container>

            <ng-template #empty>
                <div class="c-page pt-5">
                    <ruts-progressbar [isNew]="true" />
                </div>
            </ng-template>
        </main>
    </div>
</div>
