import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { API } from "@core/constants/api.constants";
import { TOKEN_STORAGE_KEY } from "@core/constants/core.constants";
import { proceedWithError } from "@core/helpers/proceed-error.helper";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { IHealthCheckResponse } from "@core/interfaces/health-check-data.interface";
import { IPeriod } from "@core/interfaces/period.interface";
import { IRouteStatus } from "@core/interfaces/route-status.interface";

import { CurrentUserMapper } from "../mappers/current-user.mapper";

@Injectable({
    providedIn: "root",
})
export class CoreService {
    private readonly _mapper: CurrentUserMapper = new CurrentUserMapper();

    constructor(private readonly _http: HttpClient) {}

    getUserMetaData(): Observable<ICurrentUser> {
        return this._http.get(API.USER_META_DATA()).pipe(
            map((data: any): ICurrentUser => {
                return this._mapper.mapFrom(data);
            })
        );
    }

    updateLimits(period: IPeriod): Observable<{ userLimits: number; usedLimits: number }> {
        return this._http.get(API.UPDATE_MAIN_LIMIT(), { params: period }).pipe(
            map((response: any): { userLimits: number; usedLimits: number } => {
                return response;
            })
        );
    }

    getMenuStatus(): Observable<IRouteStatus> {
        return this._http.get(API.MENU_STATUS()).pipe(
            map((response: any): IRouteStatus => {
                return response.data;
            })
        );
    }

    hasAuthToken(): boolean {
        return !!localStorage.getItem(TOKEN_STORAGE_KEY);
    }

    healthCheck(): Observable<boolean> {
        return this._http
            .get<IHealthCheckResponse>(
                API.HEALTH_CHECK(),
                proceedWithError({
                    500: (err: HttpErrorResponse) => throwError(() => err),
                    502: (err: HttpErrorResponse) => throwError(() => err),
                    503: (err: HttpErrorResponse) => throwError(() => err),
                    504: (err: HttpErrorResponse) => throwError(() => err),
                })
            )
            .pipe(
                map((res: IHealthCheckResponse) => res?.maintenance),
                catchError(() => of(true))
            );
    }
}
