import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

import { Observable } from "rxjs";

import { Select, Store } from "@ngxs/store";

import { SubscriberComponent } from "@core/classes/subscriber.class";
import { SearchFieldPlaceholderText } from "@core/enums/message.enum";

import { IAuthRestorePasswordData } from "../../interfaces/auth-page.interface";
import { AuthPageActions } from "../../store/auth.actions";
import { AuthPageState } from "../../store/auth.state";

@Component({
    selector: "ruts-auth-restore-password",
    templateUrl: "./auth-restore-password.component.html",
    styleUrls: ["./auth-restore-password.component.scss"],
})
export class AuthRestorePasswordComponent extends SubscriberComponent {
    public mainForm: UntypedFormGroup;
    public code: string = "";
    public email: string = "";
    public formRestore: UntypedFormGroup;
    public restoringPassFromMail: boolean = false;
    public readonly placeholder: typeof SearchFieldPlaceholderText = SearchFieldPlaceholderText;

    @Select(AuthPageState.getStatus) isLoading$: Observable<boolean>;
    @Select(AuthPageState.checkRecoveryInstructionsSent)
    recoveryInstructionsSent$: Observable<boolean>;

    constructor(
        private _store: Store,
        private _route: ActivatedRoute
    ) {
        super();

        this.addSub(
            this._route.queryParams.subscribe(params => {
                if (params.code && params.email) {
                    this.code = params.code;
                    this.email = params.email;
                    this.restoringPassFromMail = true;
                }
            })
        );
    }

    ngOnInit(): void {
        this.mainForm = new UntypedFormGroup({
            email: new UntypedFormControl(null, [Validators.required, Validators.email]),
        });

        this.formRestore = new UntypedFormGroup({
            password: new UntypedFormControl(null, [Validators.required]),
            confirmPassword: new UntypedFormControl(null, [Validators.required]),
        });
    }

    sendEmail() {
        this._store.dispatch(new AuthPageActions.SendEmail({ email: this.mainForm.value.email }));
    }

    restorePassword() {
        const data: IAuthRestorePasswordData = {
            code: this.code,
            email: this.email,
            password: this.formRestore.value.password,
        };

        this._store.dispatch(new AuthPageActions.RestorePassword({ ...data }));
    }
}
