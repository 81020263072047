import { Injectable, NgZone } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

import { mergeMap, switchMap, throwError } from "rxjs";
import { catchError, take, tap } from "rxjs/operators";

import { Navigate } from "@ngxs/router-plugin";
import { Action, Selector, State, StateContext } from "@ngxs/store";

import { StateResetAll } from "ngxs-reset-plugin";

import { PAGE_TITLE, PERIOD_STORAGE_KEY, TOKEN_STORAGE_KEY } from "@core/constants/core.constants";
import { PageName } from "@core/enums/defaults.enum";
import { ToastMessageText } from "@core/enums/message.enum";
import { ToastService } from "@core/services/toast.service";
import { GlobalActions } from "@core/store/core.actions";

import {
    IAuthLoginError,
    IAuthRegistrationData,
    IAuthRestorePasswordData,
} from "../interfaces/auth-page.interface";
import { AuthService } from "../services/auth.service";
import { AuthPageActions } from "./auth.actions";

const defaults = {
    token: null,
    inviteCode: "",
    errors: [],
    loginError: null,
    isLoading: false,
    recoveryInstructionsSent: false,
};

export class AuthStateModel {
    isLoading: boolean;
    inviteCode: string;
    errors: string[];
    loginError: IAuthLoginError | null;
    token: string | null;
    recoveryInstructionsSent: boolean;
}

@State<AuthStateModel>({
    name: "auth",
    defaults,
})
@Injectable()
export class AuthPageState {
    constructor(
        private _zone: NgZone,
        private _router: Router,
        private _titleService: Title,
        private _service: AuthService,
        private _toastService: ToastService
    ) {}

    @Selector()
    static getStatus(state: AuthStateModel) {
        return state.isLoading;
    }

    @Selector()
    static getToken(state: AuthStateModel) {
        return state.token;
    }

    @Selector()
    static isAuthenticated(state: AuthStateModel) {
        return !!state.token;
    }

    @Selector()
    static checkRecoveryInstructionsSent(state: AuthStateModel) {
        return state.recoveryInstructionsSent;
    }

    @Selector()
    static getErrors(state: AuthStateModel) {
        return state.errors;
    }

    @Action(AuthPageActions.Login)
    login(ctx: StateContext<AuthStateModel>, { payload }: AuthPageActions.Login) {
        ctx.patchState({
            errors: [],
            loginError: null,
            isLoading: true,
        });

        return this._service.login(payload.login, payload.password).pipe(
            tap(response => {
                if (!response?.data) {
                    throw new Error("No response");
                }

                localStorage.setItem(TOKEN_STORAGE_KEY, response.data.access_token);

                ctx.patchState({
                    token: response.data.access_token,
                    isLoading: false,
                });

                ctx.dispatch(new GlobalActions.AppInit())
                    .pipe(
                        take(1),
                        mergeMap(() => ctx.dispatch(new Navigate([PageName.Checker])))
                    )
                    .subscribe();
            }),
            catchError(({ error }) => {
                if (error.contact) {
                    ctx.patchState({
                        isLoading: false,
                        loginError: {
                            comment: error.comment,
                            contact: error.contact,
                        },
                    });
                }

                this.handleError(ctx, error.errors);

                return throwError(() => error);
            })
        );
    }

    @Action(AuthPageActions.SetToken)
    setToken(ctx: StateContext<AuthStateModel>) {
        return ctx.patchState({
            token: localStorage.getItem(TOKEN_STORAGE_KEY),
        });
    }

    @Action(AuthPageActions.Logout)
    logout(ctx: StateContext<AuthStateModel>, { payload }: AuthPageActions.Logout) {
        localStorage.removeItem(TOKEN_STORAGE_KEY);
        sessionStorage.removeItem(PERIOD_STORAGE_KEY);

        ctx.dispatch([new StateResetAll(), new Navigate([PageName.Auth], payload)]);

        this._titleService.setTitle(PAGE_TITLE[PageName.Auth]);
    }

    @Action(AuthPageActions.CheckInvite)
    checkInvite(ctx: StateContext<AuthStateModel>, { payload }: AuthPageActions.CheckInvite) {
        ctx.patchState({
            isLoading: true,
            loginError: null,
            errors: [],
        });

        return this._service.checkInvite(payload.code).pipe(
            tap(response => {
                ctx.patchState({
                    inviteCode: response.data.token,
                    isLoading: false,
                });

                ctx.dispatch(new Navigate([PageName.Auth, PageName.Registration]));
            }),
            catchError(({ error }) => {
                this.handleError(ctx, error.errors);

                return throwError(() => error);
            })
        );
    }

    @Action(AuthPageActions.Registration)
    registration(ctx: StateContext<AuthStateModel>, { payload }: AuthPageActions.Registration) {
        const state = ctx.getState();

        ctx.patchState({
            isLoading: true,
            loginError: null,
            errors: [],
        });

        const data: IAuthRegistrationData = {
            login: payload.login,
            email: payload.email,
            password: payload.password,
            code: state.inviteCode,
        };

        return this._service.registration(data).pipe(
            tap(response => {
                if (!response?.data) {
                    throw new Error("No response");
                }

                localStorage.setItem(TOKEN_STORAGE_KEY, response.data.access_token);

                ctx.patchState({
                    token: response?.data?.access_token,
                    isLoading: false,
                });

                this._toastService.showSuccessMessage(ToastMessageText.SucceedRegistration);
            }),
            switchMap(() => ctx.dispatch(new GlobalActions.AppInit())),
            switchMap(() => ctx.dispatch(new Navigate(["/", PageName.Documents]))),
            catchError(({ error }) => {
                this.handleError(ctx, error.errors);

                return throwError(() => error);
            })
        );
    }

    @Action(AuthPageActions.SendEmail)
    sendEmail(ctx: StateContext<AuthStateModel>, { payload }: AuthPageActions.SendEmail) {
        ctx.patchState({
            isLoading: true,
        });

        return this._service.sendEmail(payload.email).pipe(
            tap(() => {
                ctx.patchState({
                    isLoading: false,
                    recoveryInstructionsSent: true,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    isLoading: false,
                });

                return throwError(() => error);
            })
        );
    }

    @Action(AuthPageActions.RestorePassword)
    restorePassword(
        ctx: StateContext<AuthStateModel>,
        { payload }: AuthPageActions.RestorePassword
    ) {
        ctx.patchState({
            isLoading: true,
        });

        const data: IAuthRestorePasswordData = {
            code: payload.code,
            email: payload.email,
            password: payload.password,
        };

        return this._service.restorePassword(data).pipe(
            tap(() => {
                ctx.patchState({
                    isLoading: false,
                });

                this._toastService.showSuccessMessage(ToastMessageText.SucceedPasswordRestoring);
            }),
            catchError(error => {
                ctx.patchState({
                    isLoading: false,
                });

                return throwError(() => error);
            })
        );
    }

    handleError(ctx: StateContext<AuthStateModel>, errors: string[]) {
        this._toastService.showErrorMessage(ToastMessageText.LoginFailed);

        return ctx.patchState({
            isLoading: false,
            errors: errors,
        });
    }
}
