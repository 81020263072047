import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { SharedModule } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { MenuModule } from "primeng/menu";
import { SkeletonModule } from "primeng/skeleton";

import { CoreModule } from "@core/core.module";

import { MenuComponent } from "./menu.component";

@NgModule({
    declarations: [MenuComponent],
    imports: [CoreModule, RouterModule, MenuModule, SkeletonModule, ButtonModule, SharedModule],
    exports: [MenuComponent],
})
export class MenuComponentModule {}
