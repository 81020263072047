import { IPeriod } from "@core/interfaces/period.interface";

export namespace GlobalActions {
    export class AppInit {
        static readonly type = "[CORE]_APP_INIT";
    }

    export class SetCurrentUserAndPeriod {
        static readonly type = "[CORE]_SET_CURRENT_USER";
    }

    export class UpdateLimits {
        static readonly type = "[Global] Update user limits";

        constructor(public payload: IPeriod) {}
    }

    export class GetMenuStatus {
        static readonly type = "[Global] Get menu status";

        constructor() {}
    }

    export class SetActivePeriod {
        static readonly type = "[CORE]_SET_ACTIVE_PERIOD";
        constructor(public payload: IPeriod) {}
    }

    export class UpdateTochnoTime {
        static readonly type = "[CORE] UPDATE_TOCHNO_TIME";

        constructor(public payload: string) {}
    }
}
