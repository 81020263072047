import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Store } from "@ngxs/store";

import { Period } from "@core/classes/Period.class";
import { PERIODIC_URL_REGEX } from "@core/constants/core.constants";
import { GlobalActions } from "@core/store/core.actions";
import { CoreStateSelector } from "@core/store/core.selectors";

@Component({
    template: "",
})
export class PeriodWatcherLayoutComponent {
    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _store: Store
    ) {
        const routeSnapshot = _route.snapshot;
        const routeParams = routeSnapshot.paramMap;
        const workPeriod = _store.selectSnapshot(CoreStateSelector.getWorkPeriod);
        const currentPeriod = _store.selectSnapshot(CoreStateSelector.getActivePeriod);
        const periodParams = Period.create(
            {
                quarter: routeParams.get("quarter"),
                year: routeParams.get("year"),
            },
            true
        );

        if (
            Period.isValidPeriod(periodParams) &&
            !Period.areEqualPeriods(periodParams, currentPeriod)
        ) {
            this._store.dispatch(new GlobalActions.SetActivePeriod(periodParams));
        }

        if (!Period.isValidPeriod(periodParams)) {
            this._store.dispatch(new GlobalActions.SetActivePeriod(workPeriod));
        }

        const nonPeriodicUrl = this._router.routerState.snapshot.url.replace(
            PERIODIC_URL_REGEX,
            ""
        );

        void this._router.navigateByUrl(nonPeriodicUrl);
    }
}
