<div class="header-ruts flex w-full">
    <div class="c-page flex align-items-center justify-content-between">
        <ng-container *ngIf="(currentUser$ | async) && !(isLoading$ | async); else empty">
            <ng-container *ngIf="!swd.isDesktop()">
                <a [routerLink]="['/']"><i class="ruts-icon" [ngClass]="logoImg"></i></a>
            </ng-container>

            <ng-container *ngIf="currentUser$ | async as currentUser">
                <div
                    class="header_period"
                    [ngClass]="{ admin: currentUser.isAdmin, hide: !periodViewShown }"
                    (click)="togglePeriodView(HeaderTypeOfInformationShown.Period)">
                    <div class="header_period-value">
                        <ng-container *ngIf="period$ | async as period">
                            <div class="text-right">
                                Выбранный <br *ngIf="currentUser.isAdmin" />
                                квартал:
                            </div>
                            <div class="px-3">
                                {{ period.quarter }}кв{{ period.year | sliceYear }}
                            </div>
                        </ng-container>
                    </div>

                    <ng-container *ngIf="!currentUser.isAdmin && !currentUser.isInexperienced">
                        <ng-container *ngIf="isUpdatingLimits$ | async; else limitsView">
                            <p-skeleton class="w-full mt-1" borderRadius="4px" height="20px" />
                        </ng-container>

                        <ng-template #limitsView>
                            <div class="header_period-limits">
                                Лимиты:
                                <span>
                                    {{ currentUser.usedLimit }} кк /{{ currentUser.userLimit }} кк
                                </span>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>

                <ng-container *ngIf="swd.to(480)">
                    <div
                        class="header_period mobile"
                        *ngIf="periodViewShown"
                        [ngClass]="{ admin: currentUser.isAdmin }">
                        <div class="header_period-value">
                            <ng-container *ngIf="period$ | async as period">
                                <div>
                                    Выбранный <br *ngIf="currentUser.isAdmin" />
                                    квартал:
                                </div>
                                <div>{{ period.quarter }}кв{{ period.year | sliceYear }}</div>
                            </ng-container>
                        </div>
                        <div
                            class="header_period-limits"
                            *ngIf="!currentUser.isAdmin && !currentUser.isInexperienced">
                            Лимиты:
                            <span>
                                {{ currentUser.usedLimit }} кк /{{ currentUser.userLimit }} кк
                            </span>
                        </div>
                    </div>
                </ng-container>

                <div class="header_controls" *ngIf="swd.isDesktop() || selectionPeriodPanelIsOpen">
                    <div>
                        <p>
                            Выберите <br class="hidden lg:block" />
                            квартал:
                        </p>

                        <ruts-quarter-radio [(ngModel)]="quarter"/>
                    </div>
                    <div>
                        <p>
                            Выберите <br class="hidden lg:block" />
                            год:
                        </p>
                        <ruts-year-input [(ngModel)]="yearInput"/>
                    </div>
                    <button
                        label="Применить"
                        pButton
                        type="button"
                        (click)="setNewPeriod()"></button>
                </div>

                <div
                    class="header_exit"
                    [ngClass]="{ hide: periodViewShown }"
                    (click)="togglePeriodView(HeaderTypeOfInformationShown.Info)">
                    <div class="header_exit_left">
                        <i class="pi pi-user text-gray-900"></i>
                        <span class="header_exit-username">{{ currentUser.userName }}</span>
                    </div>
                    <div class="header_exit_right">
                        <span class="header_exit-out" (click)="exit()">
                            <i class="pi pi-sign-out"></i>
                            выход
                        </span>
                    </div>
                </div>

                <div class="header_exit mobile" *ngIf="swd.isXs() && userInfoIsOpen">
                    <div class="header_exit_left">
                        <i class="pi pi-user text-gray-900"></i>
                        <span class="header_exit-username">{{ currentUser.userName }}</span>
                    </div>
                    <div class="header_exit_right">
                        <span class="header_exit-out" (click)="exit()">
                            <i class="pi pi-sign-out"></i>
                            выход
                        </span>
                    </div>
                </div>

                <button
                    class="header_burger"
                    icon="pi pi-bars"
                    iconPos="left"
                    pButton
                    type="button"
                    *ngIf="!swd.isDesktop()"
                    (click)="openMenu()"></button>
            </ng-container>

            <ng-container
                *ngIf="!swd.isDesktop() && (selectionPeriodPanelIsOpen || userInfoIsOpen)">
                <div
                    class="header_actions-background"
                    [class.--h-exit]="userInfoIsOpen"
                    [class.--h-period]="selectionPeriodPanelIsOpen"></div>
            </ng-container>
        </ng-container>

        <ng-template #empty>
            <div class="header_skeleton">
                <p-skeleton />
                <p-skeleton />
                <p-skeleton />
            </div>
        </ng-template>
    </div>
</div>
