import { inject, Pipe, PipeTransform } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { Store } from "@ngxs/store";

import { Nullable } from "@angular-ru/cdk/typings";

import { getShortYear } from "@core/helpers/handlers.helper";
import { Nulled } from "@core/interfaces/core.interface";
import { CoreState } from "@core/store/core.state";

type UserWithAdmin = Nulled<{
    isAdmin?: boolean;
}>;

@Pipe({
    name: "sliceYear",
    standalone: true,
    pure: true,
})
export class SliceYearPipe implements PipeTransform {
    transform(value: number): number {
        return getShortYear(value);
    }
}
