import { getQuarter, getYear, sub } from "date-fns";

import { DefaultPeriod, DefaultsEnum } from "@core/enums/defaults.enum";
import { IPeriod } from "@core/interfaces/period.interface";

export class Period {
    get date() {
        return sub(new Date(), { months: 3 });
    }

    get year() {
        return getYear(this.date);
    }

    get workPeriod(): IPeriod {
        return {
            quarter: getQuarter(this.date),
            year: this.year,
        };
    }

    static create(data?: any, asIs?: true, debug?: true) {
        const _default = { ...DefaultPeriod };

        if (!data) return _default;

        const model = { quarter: Number(data?.quarter), year: Number(data?.year) };

        if (!Period.isPeriod(model) && !asIs) {
            if (debug) console.error("Required fields not defined in: ", model);
            return _default;
        }

        if (!Period.isValidPeriod(model) && !asIs) {
            if (debug) console.error("Values from defined period not valid in: ", model);
            return _default;
        }

        return model;
    }

    static isPeriod(obj: any): obj is IPeriod {
        return (
            typeof obj === "object" &&
            Object.keys(obj).length === 2 &&
            obj.hasOwnProperty("quarter") &&
            obj.hasOwnProperty("year") &&
            typeof obj.year === "number" &&
            typeof obj.quarter === "number"
        );
    }

    static isValidPeriod(period: any): boolean {
        return (
            Period.isPeriod(period) &&
            period.quarter >= 1 &&
            period.quarter <= 4 &&
            period.year >= DefaultsEnum.minYear &&
            period.year <= DefaultsEnum.maxYear
        );
    }

    static areEqualPeriods(a: IPeriod, b: IPeriod): boolean {
        return a.year === b.year && a.quarter === b.quarter;
    }
}
