import { Component, OnDestroy } from "@angular/core";

import { Subscription, TeardownLogic } from "rxjs";

@Component({
    template: "",
})
export abstract class SubscriberComponent implements OnDestroy {
    protected _subs: Subscription = new Subscription();

    protected constructor() {}

    ngOnDestroy() {
        this._subs.unsubscribe();
    }

    addSub(...subscriptions: TeardownLogic[] | TeardownLogic[][]): void {
        subscriptions.flat().forEach(s => this._subs.add(s));
    }
}
