import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";

import { BreadcrumbModule } from "primeng/breadcrumb";

import { FileSaverModule } from "ngx-filesaver";

import { LimitEditingComponent } from "@core/components/limit-editing/limit-editing.component";
import { ViewportIntersectionDirective } from "@core/directives";

import { ContentHeaderComponent } from "./components/content-header/content-header.component";
import { DownloadableComponent } from "./components/downloadable/downloadable.component";
import { PeriodWatcherLayoutComponent } from "./components/period-watcher-layout/period-watcher-layout.component";
import { ProgressbarComponent } from "./components/progressbar/progressbar.component";
import { SumPipe } from "./pipes/sum.pipe";
import { BreadcrumbsComponent } from '@core/components/breadcrumbs/breadcrumbs.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        FileSaverModule,
        RouterModule,
        BreadcrumbModule,
        SumPipe,
        ViewportIntersectionDirective,
        ProgressbarComponent,
        BreadcrumbsComponent,
    ],
    declarations: [
        ContentHeaderComponent,
        LimitEditingComponent,
        PeriodWatcherLayoutComponent,
        DownloadableComponent,
    ],
    exports: [
        SumPipe,
        FormsModule,
        CommonModule,
        FileSaverModule,
        HttpClientModule,
        ProgressbarComponent,
        LimitEditingComponent,
        ContentHeaderComponent,
        PeriodWatcherLayoutComponent,
        DownloadableComponent,
    ],
    providers: [Title],
})
export class CoreModule {}
