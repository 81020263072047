import { Injectable } from "@angular/core";

import { Observable, switchMap } from "rxjs";
import { map, mergeMap, take, tap } from "rxjs/operators";

import { Navigate } from "@ngxs/router-plugin";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";

import { isNotNil } from "@angular-ru/cdk/utils";

import { Period } from "@core/classes/Period.class";
import { PERIOD_STORAGE_KEY, STORAGE_DATA_SEPARATOR } from "@core/constants/core.constants";
import { DefaultPeriod, PageName } from "@core/enums/defaults.enum";
import { Nulled } from "@core/interfaces/core.interface";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { IPeriod } from "@core/interfaces/period.interface";
import { IRouteStatus } from "@core/interfaces/route-status.interface";
import { CoreService } from "@core/services/core.service";
import { GlobalActions } from "@core/store/core.actions";

import { AuthPageActions } from "../../auth-page/store/auth.actions";
import { AuthPageState } from "../../auth-page/store/auth.state";

function checkAndApplySavedPeriod(ctx: StateContext<CoreStateModel>): void {
    const savedPeriod: Nulled<string> = sessionStorage.getItem(PERIOD_STORAGE_KEY);

    if (isNotNil(savedPeriod)) {
        const parsedPeriod: string[] = savedPeriod.split(STORAGE_DATA_SEPARATOR);

        if (parsedPeriod.length == 2) {
            const period: IPeriod = Period.create(
                { quarter: parsedPeriod[0], year: parsedPeriod[1] },
                true
            );
            const state: CoreStateModel = ctx.getState();

            if (
                Period.isValidPeriod(period) &&
                !Period.areEqualPeriods(state.periods.active, period)
            ) {
                ctx.patchState({
                    periods: {
                        ...state.periods,
                        active: period,
                        changed: !Period.areEqualPeriods(state.periods.work, period),
                    },
                });
            }
        }
    }
}

export class CoreStateModel {
    isLoading: boolean;
    menuStatus: Nulled<IRouteStatus>;
    currentUser: Nulled<ICurrentUser>;
    period: IPeriod;
    periods: {
        work: IPeriod;
        active: IPeriod;
        changed: boolean;
    };
}

const defaults: CoreStateModel = {
    isLoading: false,
    currentUser: null,
    menuStatus: null,
    period: DefaultPeriod,
    periods: {
        work: DefaultPeriod,
        active: DefaultPeriod,
        changed: false,
    },
};

@State<CoreStateModel>({
    name: "core",
    defaults,
})
@Injectable()
export class CoreState {
    constructor(
        private readonly _currentUserService: CoreService,
        private readonly _store: Store
    ) {}

    @Selector()
    public static getUser(state: CoreStateModel): Nulled<ICurrentUser> {
        return state.currentUser;
    }

    @Selector()
    public static getPeriod(state: CoreStateModel): IPeriod {
        return state.periods.active;
    }

    @Selector()
    public static getStatus(state: CoreStateModel): boolean {
        return state.isLoading;
    }

    @Selector()
    public static getMenuStatus(state: CoreStateModel): Nulled<IRouteStatus> {
        return state.menuStatus;
    }

    public ngxsOnInit(ctx: StateContext<CoreStateModel>): void {
        ctx.dispatch([new AuthPageActions.SetToken()])
            .pipe(
                take(1),
                switchMap(() =>
                    this._currentUserService.healthCheck().pipe(
                        map((isMaintenanceMode: boolean) => {
                            if (isMaintenanceMode) {
                                return ctx.dispatch(new Navigate([PageName.Maintenance]));
                            } else {
                                const hasToken: boolean = Boolean(
                                    this._store.select(AuthPageState.getToken)
                                );

                                if (hasToken) {
                                    checkAndApplySavedPeriod(ctx);
                                    return ctx.dispatch([
                                        new GlobalActions.SetCurrentUserAndPeriod(),
                                    ]);
                                }

                                return ctx.dispatch(new Navigate([PageName.Auth]));
                            }
                        })
                    )
                )
            )
            .subscribe();
    }

    @Action(GlobalActions.AppInit)
    public appInit(ctx: StateContext<CoreStateModel>): Observable<void> {
        checkAndApplySavedPeriod(ctx);
        return ctx.dispatch(new GlobalActions.SetCurrentUserAndPeriod());
    }

    @Action(GlobalActions.SetCurrentUserAndPeriod)
    public setCurrentUserAndPeriod(ctx: StateContext<CoreStateModel>): Observable<ICurrentUser> {
        return this._currentUserService.getUserMetaData().pipe(
            tap((data: ICurrentUser) => {
                const newWorkPeriod = {
                    quarter: data.quarter || DefaultPeriod.quarter,
                    year: data.year || DefaultPeriod.year,
                };
                let state = ctx.getState();

                ctx.patchState({
                    currentUser: { ...data },
                    period: {
                        year: data.year,
                        quarter: data.quarter,
                    },
                    periods: {
                        ...state.periods,
                        work: newWorkPeriod,
                        changed: !Period.areEqualPeriods(state.periods.active, newWorkPeriod),
                    },
                });

                state = ctx.getState();

                if (!data.isAdmin && state.periods.changed) {
                    ctx.dispatch(new GlobalActions.UpdateLimits(state.periods.active));
                } else {
                    ctx.dispatch(new GlobalActions.GetMenuStatus());
                }
            })
        );
    }

    @Action(GlobalActions.SetActivePeriod)
    public setActivePeriod(
        ctx: StateContext<CoreStateModel>,
        { payload }: GlobalActions.SetActivePeriod
    ): void {
        const state: CoreStateModel = ctx.getState();

        if (!Period.areEqualPeriods(state.periods.active, payload)) {
            sessionStorage.setItem(
                PERIOD_STORAGE_KEY,
                [payload.quarter, payload.year].join(STORAGE_DATA_SEPARATOR)
            );
        }

        ctx.patchState({
            periods: {
                ...state.periods,
                active: { ...payload },
                changed: !Period.areEqualPeriods(payload, state.periods.work),
            },
        });

        if (!state.currentUser?.isAdmin) {
            ctx.dispatch(new GlobalActions.UpdateLimits(payload));
        }
    }

    @Action(GlobalActions.UpdateLimits)
    public updateLimits(
        ctx: StateContext<CoreStateModel>,
        { payload }: GlobalActions.UpdateLimits
    ): Observable<void> {
        return this._currentUserService.updateLimits(payload).pipe(
            tap(response => {
                ctx.patchState({
                    period: { ...payload },
                    currentUser: {
                        ...(ctx.getState().currentUser as ICurrentUser),
                        userLimit: Math.round(response.userLimits / 1000000),
                        usedLimit: Math.round(response.usedLimits / 1000000),
                    },
                });
            }),
            mergeMap(() => ctx.dispatch(new GlobalActions.GetMenuStatus()))
        );
    }

    @Action(GlobalActions.GetMenuStatus, { cancelUncompleted: true })
    public getMenuStatus(ctx: StateContext<CoreStateModel>): Observable<IRouteStatus> {
        return this._currentUserService.getMenuStatus().pipe(
            tap((response: IRouteStatus) => {
                ctx.patchState({
                    menuStatus: response,
                });
            })
        );
    }

    @Action(GlobalActions.UpdateTochnoTime)
    updateTochnoTime(
        ctx: StateContext<CoreStateModel>,
        { payload }: GlobalActions.UpdateTochnoTime
    ) {
        ctx.patchState({
            currentUser: {
                ...(ctx.getState().currentUser as ICurrentUser),
                tochnoTime: payload,
            },
        });
    }
}
