import { Component } from "@angular/core";

import { delay, Observable } from "rxjs";

import { Actions, Select } from "@ngxs/store";

import { PrimeNGConfig } from "primeng/api";

import { SubscriberComponent } from "@core/classes/subscriber.class";
import { PRIME_CALENDAR_CONFIG } from "@core/constants/core.constants";
import { hasActionsRunning } from "@core/helpers/handlers.helper";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { GlobalActions } from "@core/store/core.actions";
import { CoreState } from "@core/store/core.state";

@Component({
    selector: "ruts-site-layout",
    templateUrl: "./site-layout.component.html",
    styleUrls: ["./site-layout.component.scss"],
})
export class SiteLayoutComponent extends SubscriberComponent {
    @Select(CoreState.getUser) readonly currentUser$: Observable<ICurrentUser>;

    headerIsLoading$: Observable<boolean>;
    contentUpdating$: Observable<boolean>;

    constructor(
        private readonly _primeConfig: PrimeNGConfig,
        private readonly _actions$: Actions
    ) {
        super();
        this._primeConfig.setTranslation(PRIME_CALENDAR_CONFIG);
    }

    ngOnInit(): void {
        this.headerIsLoading$ = hasActionsRunning(this._actions$, [GlobalActions.AppInit]);
        this.contentUpdating$ = hasActionsRunning(this._actions$, [
            GlobalActions.SetActivePeriod,
        ]).pipe(delay(500));

        this.addSub([
            this.contentUpdating$.subscribe(res =>
                console.info("Content layout update " + (res ? "started" : "finished"))
            ),
        ]);
    }
}
