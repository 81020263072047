import { Role } from "@core/enums/defaults.enum";
import { ICurrentUser } from "@core/interfaces/current-user.interface";

import { BaseMapper } from "./base.mapper";

export class CurrentUserMapper extends BaseMapper<ICurrentUser> {
    mapFrom(data: any): ICurrentUser {
        const currentUser: ICurrentUser = {} as ICurrentUser;

        currentUser.year = this.typeCast.getAsNumber(data.year);
        currentUser.quarter = this.typeCast.getAsNumber(data.quarter);
        currentUser.userId = this.typeCast.getAsNumber(data.id);
        currentUser.userName = this.typeCast.getAsString(data.login);
        currentUser.tochnoTime = this.typeCast.getAsString(data.tochnoTime);
        currentUser.rights = this.typeCast.getAsTypedArray(data.rights, this.typeCast.getAsString);
        currentUser.contact = this.typeCast.getAsString(data.contact);
        currentUser.isAdmin = this.typeCast.getAsBoolean(data.role === Role.isAdmin);
        currentUser.isPro = this.typeCast.getAsBoolean(data.isPro);
        currentUser.isInexperienced = !data.role && !data.rights.length;
        currentUser.isKulak = data.level === 1 && data.role === Role.isKulak;
        currentUser.isBatrak = data.level === 2 && data.role === Role.isAdvanced;
        currentUser.isHolop = data.level === 3 && data.role === Role.isAdvanced;
        currentUser.partnersAvailable = this.typeCast.getAsBoolean(data.partnersAvailable);
        currentUser.userLimit = Math.round(this.typeCast.getAsNumber(data.userLimit, 0) / 1000000);
        currentUser.usedLimit = Math.round(this.typeCast.getAsNumber(data.usedLimit, 0) / 1000000);

        return currentUser;
    }
}
