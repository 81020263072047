import { Params } from "@angular/router";

export namespace AuthPageActions {
    export class Login {
        static readonly type = "[Auth login Page] Login";
        constructor(public payload: { login: string; password: string }) {}
    }

    export class SetToken {
        static readonly type = "[Auth Page] Set token";
        constructor() {}
    }

    export class Logout {
        static readonly type = "[Auth Page] Logout";
        constructor(public payload?: Params) {}
    }

    export class CheckInvite {
        static readonly type = "[Auth Page] Check invite";
        constructor(public payload: { code: string }) {}
    }

    export class Registration {
        static readonly type = "[Auth Page] Registration";
        constructor(public payload: { login: string; password: string; email: string }) {}
    }

    export class SendEmail {
        static readonly type = "[Auth Page] Send email for restoring password";
        constructor(public payload: { email: string }) {}
    }

    export class RestorePassword {
        static readonly type = "[Auth Page] Restore password";
        constructor(public payload: { code: string; email: string; password: string }) {}
    }
}
