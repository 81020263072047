import {
    AfterViewInit,
    Attribute,
    DestroyRef,
    Directive,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { Subject } from "rxjs";
import { distinctUntilChanged, map, mergeMap } from "rxjs/operators";

import { Nullable } from "@angular-ru/cdk/typings";

@Directive({
    standalone: true,
    selector: "[rutsViewportIntersection]",
})
export class ViewportIntersectionDirective
    extends IntersectionObserver
    implements OnInit, AfterViewInit, OnDestroy
{
    @Output() readonly intersect: EventEmitter<boolean> = new EventEmitter<boolean>();

    private readonly intersectionObserverSubject: Subject<IntersectionObserverEntry[]> =
        new Subject<IntersectionObserverEntry[]>();

    constructor(
        private readonly destroyRef: DestroyRef,
        private readonly targetElementRef: ElementRef,
        @Attribute("rutsRootMargin") private readonly rootMarginValue: Nullable<string>,
        @Attribute("rutsThreshold") private readonly thresholdValue: Nullable<number>
    ) {
        super(
            (entries: IntersectionObserverEntry[]) => {
                this.intersectionObserverSubject.next(entries);
            },
            {
                root: null,
                rootMargin: rootMarginValue || "-90px 0px 0px 0px",
                threshold: thresholdValue ?? 0,
            }
        );
    }

    ngOnInit(): void {
        this.observe(this.targetElementRef.nativeElement);
    }

    ngAfterViewInit(): void {
        this.intersectionObserverSubject
            .pipe(
                mergeMap((entries: IntersectionObserverEntry[]) => entries),
                map((entry: IntersectionObserverEntry) => !entry.isIntersecting),
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((value: boolean) => this.intersect.emit(value));
    }

    ngOnDestroy(): void {
        this.disconnect();
    }
}
