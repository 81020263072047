import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

import { MenuItem } from "primeng/api";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { BreadcrumbItemClickEvent } from "primeng/breadcrumb/breadcrumb.interface";

@Component({
    selector: "ruts-breadcrumbs",
    standalone: true,
    imports: [CommonModule, BreadcrumbModule],
    templateUrl: "./breadcrumbs.component.html",
    styleUrls: ["./breadcrumbs.component.scss"],
})
export class BreadcrumbsComponent {
    @Input() breadcrumbItems?: MenuItem[] | null;
    @Output() onItemClick = new EventEmitter<BreadcrumbItemClickEvent>();
}
