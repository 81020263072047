import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { Observable } from "rxjs";

import { Select, Store } from "@ngxs/store";

import { PageName } from "@core/enums/defaults.enum";
import { SearchFieldPlaceholderText } from "@core/enums/message.enum";

import { AuthPageActions } from "../../store/auth.actions";
import { AuthPageState } from "../../store/auth.state";

@Component({
    selector: "ruts-auth-registration",
    templateUrl: "./auth-registration.component.html",
    styleUrls: ["./auth-registration.component.scss"],
})
export class AuthRegistrationComponent implements OnInit {
    public form: UntypedFormGroup;
    public PageName = PageName;
    readonly placeholder: typeof SearchFieldPlaceholderText = SearchFieldPlaceholderText;

    @Select(AuthPageState.getStatus) isLoading$: Observable<boolean>;
    @Select(AuthPageState.getErrors) errors$: Observable<string[]>;

    constructor(
        protected _store: Store,
        private _router: Router
    ) {
        if (!this._store.snapshot().auth.inviteCode) {
            void this._router.navigate(["/"]);
        }
    }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({
            login: new UntypedFormControl(null, [Validators.required]),
            password: new UntypedFormControl(null, [Validators.required]),
            email: new UntypedFormControl(null, [Validators.required, Validators.email]),
        });
    }

    onSubmit() {
        const { login, password, email } = this.form.value;

        this._store.dispatch(new AuthPageActions.Registration({ login, password, email }));
    }
}
