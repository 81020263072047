import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { API } from "@core/constants/api.constants";

import { IAuthRegistrationData, IAuthRestorePasswordData } from "../interfaces/auth-page.interface";

@Injectable()
export class AuthService {
    constructor(private _http: HttpClient) {}

    login(login: string, password: string): Observable<any> {
        return this._http.post(API.AUTH.LOGIN(), { login, password });
    }

    checkInvite(code: string): Observable<any> {
        return this._http.post(API.AUTH.INVITE(), { code });
    }

    registration(data: IAuthRegistrationData): Observable<any> {
        return this._http.post(API.AUTH.REGISTRATION(), { ...data });
    }

    sendEmail(email: string): Observable<any> {
        return this._http.post(API.AUTH.RESTORE_PASSWORD(), { email });
    }

    restorePassword(data: IAuthRestorePasswordData): Observable<any> {
        return this._http.post(API.AUTH.RESET_PASSWORD(), { ...data });
    }
}
