<div rutsViewportIntersection (intersect)="toggleStickyBlock($event)"></div>

<div class="content-header" [ngClass]="{ 'b-sticky': sticky, '--fixed': sticky && isBlockFixed }">
    <div class="c-page" [style.padding-top]="withBreadcrumb ? '10px' : '0'">
        <ruts-breadcrumbs
            class="content-header-breadcrumb"
            *ngIf="withBreadcrumb"
            [breadcrumbItems]="breadcrumbItems" />

        <div
            class="flex w-full flex-column sm:flex-row sm:justify-content-between sm:align-items-center">
            <h1 class="--h-40">{{ title }}</h1>

            <div class="text-right" *ngIf="topContentComponent">
                <ng-container *ngComponentOutlet="topContentComponent" />
            </div>
        </div>

        <div
            #dynamicContentTpl
            class="content-header_main"
            [class.mt-5]="dynamicContentTpl?.children?.length">
            <ng-content />
        </div>
    </div>
</div>
