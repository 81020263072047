import { booleanAttribute, Component, Input } from "@angular/core";

@Component({
    selector: "ruts-downloadable",
    template: `
        <ng-container *ngIf="isLoading; else content">
            <ruts-progressbar [isNew]="true" [style]="style" />
        </ng-container>
        <ng-template #content>
            <ng-content />
        </ng-template>
    `,
    styles: [],
})
export class DownloadableComponent {
    @Input() style: { [key: string]: any };
    @Input({ required: true, transform: booleanAttribute }) isLoading: boolean;
}
