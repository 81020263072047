import { format, parse, parseISO } from "date-fns";

export class TypeCastHelper {
    getAsString(data: any): string {
        return data !== undefined && data !== null && !Number.isNaN(data) ? String(data) : "";
    }

    getAsNumber(data: any, defaultValue?: number): number {
        const result = Number(data);

        return data === null || Number.isNaN(result) ? defaultValue ?? NaN : result;
    }

    getAsBoolean(data: any): boolean {
        const stringExceptions = ["false", "0", "null"];

        if (typeof data === "string" && stringExceptions.includes(data)) {
            return false;
        }

        return Boolean(data);
    }

    getAsNull(data: any, handler?: Function): any {
        if (data !== undefined && data !== null && !Number.isNaN(data)) {
            return handler ? handler(data) : data;
        }

        return null;
    }

    getAsType(value: any, type: any): any {
        if (!!value && !Object.values(type).includes(value)) {
            console.error(`getAsTyped: the value "${value}" was not found in the specified type`);
            return "unknown";
        }

        return value;
    }

    getAsTypedArray<T>(data: any[], cb: (item: any) => T, showError = true): T[] {
        if (!Array.isArray(data)) {
            if (showError) {
                console.error(
                    "getAsTypedArray: Given data must be an array (now it is " +
                        typeof data +
                        "). Incorrect data will be defined as array of data (data => [data])."
                );
            }

            data = [data];
        }

        return data.map(cb);
    }

    getAsDate(
        date: any,
        asString?: boolean,
        parseFormatOptions?: string,
        formatOptions?: string
    ): Date | string {
        formatOptions = formatOptions ?? "dd.MM.yyyy";
        asString = asString ?? true;

        const type = typeof date;

        switch (type) {
            case "number":
                date = new Date(date);
                break;
            case "string":
                date = !!parseFormatOptions
                    ? parse(date, parseFormatOptions, new Date())
                    : parseISO(date);
                break;
        }

        if (!date || !date.getTime()) return "";

        return asString ? format(date, formatOptions) : (date as Date);
    }
}
