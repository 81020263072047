import {distinctUntilChanged, identity, merge, Observable, startWith} from "rxjs";
import { filter, map } from "rxjs/operators";

import {
    Actions,
    ActionStatus,
    ActionType,
    getActionTypeFromInstance,
    ofActionCompleted,
    ofActionDispatched,
} from "@ngxs/store";

import { PrimitiveType } from "@core/enums/defaults.enum";
import { Direction } from "@core/interfaces/core.interface";
import { IPeriod } from "@core/interfaces/period.interface";

export function sortBy(
    field: string | number = "chainId",
    direction: Direction = "asc"
): (a: any, b: any) => number {
    const symbol = direction === "asc" ? 1 : -1;

    return function (a: any, b: any): number {
        if (a[field] > b[field]) {
            return symbol;
        } else if (a[field] < b[field]) {
            return -symbol;
        }

        return 0;
    };
}

export function orderBy<T>(fields: (keyof T)[], directions: Direction | Direction[]) {
    const directioner = (dir: Direction) => (dir === "asc" ? 1 : -1);

    return function (a: T, b: T) {
        let result = 0;

        for (let i = 0; i < fields.length; i++) {
            if (a[fields[i]] > b[fields[i]]) {
                result = directioner(
                    Array.isArray(directions) ? directions[i] || "asc" : directions || "asc"
                );
                break;
            } else if (a[fields[i]] < b[fields[i]]) {
                result = -directioner(
                    Array.isArray(directions) ? directions[i] || "asc" : directions || "asc"
                );
                break;
            }
        }

        return result;
    };
}

export function findByField(data: any[], field: string | number, value: PrimitiveType) {
    return data.filter(entity => entity[field] === value);
}

export function roundForSum(number: number): number {
    return Math.round(number * 100) / 100;
}

export function trackBy(field: string = "id") {
    return (index: number, object: any) => object[field] ?? index;
}

export function asMultidimensional<T>(array: T[], innerSize: number): Array<T[]> {
    const arraySize = array.length;

    if (!arraySize) return [];
    if (arraySize <= innerSize) return array.map(item => [item]);

    const innerLastIndex = innerSize - 1;
    const innerBaseSize = Math.floor(arraySize / innerSize);
    let arrayTail = arraySize % innerSize;
    let innerSchema: number[] = new Array(innerSize).fill(innerBaseSize);

    if (arrayTail) {
        let compensationSize = innerLastIndex - arrayTail;

        if (arrayTail > 1 && compensationSize && compensationSize < innerSchema[innerLastIndex]) {
            innerSchema[innerLastIndex] -= compensationSize;
            arrayTail += compensationSize;
        }

        for (let i = 0; i < arrayTail; i++) {
            innerSchema[i] = ++innerSchema[i];
        }
    }

    return innerSchema.reduce<{ innerStart: number; result: T[][] }>(
        (acc, innerSize) => {
            const start = acc.innerStart;
            const end = start + innerSize;

            acc.innerStart = end;
            acc.result.push(array.slice(start, end));

            return acc;
        },
        {
            innerStart: 0,
            result: [],
        }
    ).result;
}

export const uniqBy = (arr: any[], predicate: any) => {
    const cb = typeof predicate === "function" ? predicate : (o: any) => o[predicate];

    return [
        ...arr
            .reduce((map, item) => {
                const key = item === null || item === undefined ? item : cb(item);

                map.has(key) || map.set(key, item);

                return map;
            }, new Map())
            .values(),
    ];
};

export function hasActionsRunning(actions$: Actions, actionTypes: ActionType[]) {
    const memory = new Set<string>();
    return merge(
        ...actionTypes
            .map(actionType => [
                actions$.pipe(
                    ofActionDispatched(actionType),
                    map(() => {
                        memory.add(actionType.type);
                        return true;
                    })
                ),
                actions$.pipe(
                    ofActionCompleted(actionType),
                    map(() => {
                        memory.delete(actionType.type);
                        return memory.size > 0;
                    })
                ),
            ])
            .flat()
    ).pipe(startWith(false), distinctUntilChanged());
}

export function checkTochnoDay(data: any) {
    const date = new Date(data.year, data.month, data.day);

    return date.getDay() === 1 || date.getDay() === 3 || date.getDay() === 5;
}

export function generateLoader(
    actions$: Actions,
    actionTypes: ActionType[],
    useStartWith?: true
): Observable<boolean> {
    return actions$.pipe(
        filter(actionContext => {
            const actionType = getActionTypeFromInstance(actionContext.action);
            const watchedTypes = actionTypes.map(action => getActionTypeFromInstance(action));

            return watchedTypes.includes(actionType);
        }),
        map(actionContext => actionContext.status === ActionStatus.Dispatched),
        useStartWith ? startWith(true) : identity
    );
}

export function getShortYear(year: number): number {
    const preparedYear = year.toString();

    switch (preparedYear.length) {
        case 2:
            return year;
        case 4:
            return Number(preparedYear.slice(-2));
        default:
            throw new Error(year + " is not an year number");
    }
}

export function getShortPeriod({ quarter, year }: IPeriod): string {
    return `${quarter}кв${getShortYear(year)}`;
}

export function escapedBase64(str: string): string {
    return encodeURIComponent(btoa(str));
}

export function objectKeys<T extends Object>(obj: T) {
    return Object.keys(obj) as Array<keyof T>;
}
