import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { PeriodWatcherLayoutComponent } from "@core/components/period-watcher-layout/period-watcher-layout.component";
import {
    canAuthenticate,
    isAuthenticated,
    isAvailableRoleSimple,
    isMaintenanceMode,
    periodicUrlMatcher,
} from "@core/constants/guard.constants";
import { PageName } from "@core/enums/defaults.enum";
import { SiteLayoutComponent } from "@core/layouts/site-layout/site-layout.component";

import { MaintenanceComponent } from "./maintenance/maintenance.component";

const routes: Routes = [
    {
        path: PageName.Maintenance,
        component: MaintenanceComponent,
        canMatch: [isMaintenanceMode],
    },
    {
        path: PageName.Auth,
        loadChildren: () => import("./auth-page/auth.module").then(m => m.AuthModule),
        canMatch: [canAuthenticate],
    },
    {
        path: "",
        component: SiteLayoutComponent,
        canMatch: [isAuthenticated],
        children: [
            {
                path: PageName.Users,
                children: [
                    {
                        path: "",
                        loadChildren: () => import("./users/users.module").then(m => m.UsersModule),
                    },
                    {
                        path: PageName.Invites,
                        loadChildren: () =>
                            import("./invites/invites.module").then(m => m.InvitesModule),
                        data: {
                            goToBack: PageName.Users,
                        },
                    },
                    {
                        path: PageName.Aquariums,
                        loadChildren: () =>
                            import("./aquariums/aquariums.module").then(m => m.AquariumsModule),
                        data: {
                            goToBack: PageName.Users,
                        },
                    },
                    {
                        path: PageName.External,
                        loadChildren: () =>
                            import("./external/external.module").then(m => m.ExternalModule),
                        data: {
                            goToBack: PageName.Users,
                        },
                    },
                ],
            },
            {
                path: PageName.Books,
                loadChildren: () => import("./books/books.module").then(m => m.BooksModule),
            },
            {
                path: PageName.Checker,
                loadChildren: () => import("./checker/checker.module").then(m => m.CheckerModule),
            },
            {
                path: PageName.Control,
                loadChildren: () => import("./control/control.module").then(m => m.ControlModule),
            },
            {
                path: PageName.SubPartners,
                loadChildren: () =>
                    import("./sub-partners/sub-partners.module").then(m => m.SubPartnersModule),
            },
            {
                path: PageName.Dressing,
                loadChildren: () =>
                    import("./dressing/dressing.module").then(m => m.DressingModule),
            },
            {
                path: PageName.Documents,
                loadChildren: () =>
                    import("./documents/documents.module").then(m => m.DocumentsModule),
            },
            {
                path: PageName.Invoice,
                loadChildren: () =>
                    import("./invoices/admin/invoices.module").then(m => m.InvoicesModule),
                canMatch: [isAvailableRoleSimple(["admin"])],
            },
            {
                path: PageName.Invoice,
                loadChildren: () =>
                    import("./invoices/user/invoice.module").then(m => m.InvoiceModule),
            },
            {
                path: PageName.Chains,
                loadChildren: () => import("./chains/chains.module").then(m => m.ChainsModule),
            },
            {
                path: PageName.Breach,
                loadChildren: () => import("./breach/breach.module").then(m => m.BreachModule),
            },
            {
                matcher: periodicUrlMatcher,
                component: PeriodWatcherLayoutComponent,
            },
            {
                path: "**",
                redirectTo: PageName.Checker,
                pathMatch: "full",
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
