import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { ButtonModule } from "primeng/button";
import { InputNumberModule } from "primeng/inputnumber";
import { RadioButtonModule } from "primeng/radiobutton";
import { SkeletonModule } from "primeng/skeleton";

import { CoreModule } from "@core/core.module";
import { SliceYearPipe } from "@core/pipes/slice-year.pipe";

import { HeaderComponent } from "./header.component";
import { QuarterRadioComponent } from '@core/components/header/quarter-radio/quarter-radio.component';
import { YearInputComponent } from '@core/components/header/year-input/year-input.component';

@NgModule({
    declarations: [HeaderComponent],
    imports: [
        CoreModule,
        RadioButtonModule,
        InputNumberModule,
        ButtonModule,
        RouterModule,
        SkeletonModule,
        SliceYearPipe,
        QuarterRadioComponent,
        YearInputComponent,
    ],
    exports: [HeaderComponent],
})
export class HeaderModule {}
