import { DOCUMENT } from "@angular/common";
import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { Observable, startWith } from "rxjs";
import { filter, map } from "rxjs/operators";

import { Actions, Select, Store } from "@ngxs/store";

import { MenuItem } from "primeng/api";

import { SubscriberComponent } from "@core/classes/subscriber.class";
import { hasActionsRunning } from "@core/helpers/handlers.helper";
import { Nulled } from "@core/interfaces/core.interface";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { IRouteStatus } from "@core/interfaces/route-status.interface";
import { ScreenWidthDetectorService } from "@core/services/screen-width-detector.service";
import { GlobalActions } from "@core/store/core.actions";
import { CoreState } from "@core/store/core.state";

import { environment } from "../../../../environments/environment";
import { getMenuModel } from "./enums/menu.enum";

@Component({
    selector: "ruts-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class MenuComponent extends SubscriberComponent {
    logoImg: string = environment.logo;
    menuCopyright: string = environment.production ? "ruts.pro" : "ng.ruts.pro";

    readonly menuStatus$: Observable<Nulled<IRouteStatus>> = this._store.select(
        CoreState.getMenuStatus
    );
    readonly isLoading$ = hasActionsRunning(this._actions$, [GlobalActions.AppInit]);
    readonly user$ = this._store.select(CoreState.getUser);

    menuItems$: Observable<MenuItem[]> = this.user$.pipe(
        map(currentUser =>
            getMenuModel(
                !!currentUser?.isAdmin,
                !!currentUser?.partnersAvailable,
                !!currentUser?.isInexperienced,
                currentUser?.contact ?? ""
            )
        ),
        startWith(getMenuModel(false, false, false, ""))
    );

    constructor(
        protected readonly _store: Store,
        protected readonly _route: ActivatedRoute,
        private readonly _actions$: Actions,
        public readonly swd: ScreenWidthDetectorService,
        @Inject(DOCUMENT) private readonly _document: Document
    ) {
        super();
    }

    ngOnInit() {
        this.addSub([
            this.menuStatus$.subscribe(item => {
                const menuItem =
                    this._document.querySelector(".p-menuitem-icon.breach")?.parentElement;

                if (item?.breach) {
                    return menuItem?.classList.add("has-unread");
                }

                return menuItem?.classList.remove("has-unread");
            }),
        ]);
    }

    closeMenu() {
        const menu = this._document.querySelector(".ruts-menu");

        menu?.classList.remove("active");
    }
}
