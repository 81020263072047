<form class="ruts-auth" [formGroup]="form" (ngSubmit)="onSubmit()">
    <i class="ruts-icon icon-logo" [routerLink]="'/'"></i>

    <div class="auth-form">
        <ng-container *ngIf="errors$ | async as errors">
            <div class="auth-error" *ngFor="let error of errors">
                <p>{{ error }}</p>
            </div>
        </ng-container>

        <div class="auth-error" *ngIf="form.get('login')?.invalid && form.get('login')?.touched">
            <p *ngIf="form.get('login')?.hasError('required')">Логин не должен быть пустым</p>
        </div>
        <div
            class="auth-error"
            *ngIf="form.get('password')?.invalid && form.get('password')?.touched">
            <p *ngIf="form.get('password')?.hasError('required')">Пароль не должен быть пустым</p>
        </div>

        <input
            formControlName="login"
            pInputText
            type="text"
            [placeholder]="placeholder.CreateLogin" />

        <p-password
            formControlName="password"
            [placeholder]="placeholder.CreatePassword"
            [feedback]="false"
            [ngClass]="{
                'invalid-password': form.get('password')?.dirty && !form.get('password')?.value
            }"
            [toggleMask]="true" />

        <input
            formControlName="email"
            pInputText
            type="email"
            [placeholder]="placeholder.YourEmail" />

        <button
            label="Зарегистрироваться"
            pButton
            type="submit"
            [disabled]="form.invalid || form.disabled || (isLoading$ | async)"></button>
    </div>

    <div class="auth-navigation-block">
        <a [routerLink]="['/', PageName.Auth, PageName.AuthInvites]"> Вернуться назад </a>
    </div>
</form>
