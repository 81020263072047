<form class="ruts-auth" [formGroup]="form" (ngSubmit)="onSubmit()">
    <i class="ruts-icon" [ngClass]="logoImg"></i>

    <div class="auth-form">
        <ng-container *ngIf="errors$ | async as errors">
            <div class="auth-error" *ngFor="let error of errors">
                <p>{{ error }}</p>
            </div>
        </ng-container>
        <div class="auth-error" *ngIf="error">
            <p *ngIf="error.comment" [style.text-transform]="'none'">
                {{ error.comment }}
                <a
                    class="auth_tg-link"
                    target="_blank"
                    href="https://t.me/{{ error.contact }}"
                    *ngIf="error.contact">
                    @{{ error.contact }}
                </a>
            </p>
        </div>
        <div class="auth-error" *ngIf="form.get('login')?.invalid && form.get('login')?.touched">
            <p *ngIf="form.get('login')?.hasError('required')">Логин не должен быть пустым</p>
        </div>
        <div
            class="auth-error"
            *ngIf="form.get('password')?.invalid && form.get('password')?.touched">
            <p *ngIf="form.get('password')?.hasError('required')">Пароль не должен быть пустым</p>
        </div>

        <input
            formControlName="login"
            type="text"
            pInputText
            [placeholder]="placeholder.LoginOrEmail" />

        <p-password
            formControlName="password"
            [placeholder]="placeholder.Password"
            [ngClass]="{
                'invalid-password': form.get('password')?.dirty && !form.get('password')?.value
            }"
            [toggleMask]="true"
            [feedback]="false" />

        <button
            pButton
            type="submit"
            label="Войти"
            [disabled]="form.invalid || form.disabled || (isLoading$ | async)"></button>
    </div>

    <div class="auth-navigation-block">
        <a [routerLink]="pageName.AuthInvites">У меня есть инвайт</a>
    </div>
</form>
