<form class="ruts-auth invite" [formGroup]="form" (ngSubmit)="onSubmit()">
    <i class="ruts-icon icon-logo" [routerLink]="'/'"></i>

    <div class="auth-form">
        <ng-container *ngIf="errors$ | async as errors">
            <div class="auth-error" *ngFor="let error of errors">
                <p>{{ error }}</p>
            </div>
        </ng-container>

        <div class="auth-error" *ngIf="form.get('invite')?.invalid && form.get('invite')?.touched">
            <p *ngIf="form.get('invite')?.hasError('required')">Инвайт не должен быть пустым</p>
        </div>

        <input
            formControlName="invite"
            pInputText
            type="text"
            [placeholder]="placeholder.Invitation" />

        <button
            label="Проверить"
            pButton
            type="submit"
            [disabled]="form.invalid || form.disabled || (isLoading$ | async)"></button>
    </div>

    <div class="auth-navigation-block">
        <a [routerLink]="PageName.Auth">Вернуться назад</a>
    </div>
</form>
