import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { Store } from "@ngxs/store";

@Component({
    selector: "[ruts-limit-editing]",
    templateUrl: "./limit-editing.component.html",
    styleUrls: ["./limit-editing.component.scss"],
})
export class LimitEditingComponent implements OnInit {
    public isEditing: boolean = false;
    public defaultValue: number;

    @Input() id: number;
    @Input() usedLimit: number;
    @Input() userLimit: number;

    @Output() onSave = new EventEmitter<{ id: number; limit: number }>();

    constructor(private _store: Store) {}

    ngOnInit() {
        this.defaultValue = this.userLimit;
    }

    sendNewUserLimit() {
        if (this.userLimit === null) {
            return;
        }

        this.isEditing = false;

        if (this.defaultValue === this.userLimit) {
            return;
        }

        this.onSave.emit({
            id: this.id,
            limit: this.userLimit,
        });

        this.defaultValue = this.userLimit;
    }
}
