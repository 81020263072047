import { Selector } from "@ngxs/store";

import { CoreState, CoreStateModel } from "@core/store/core.state";

export class CoreStateSelector {
    @Selector([CoreState])
    static getPeriods(state: CoreStateModel) {
        return state.periods;
    }

    @Selector([CoreStateSelector.getPeriods])
    static getWorkPeriod(periods: CoreStateModel["periods"]) {
        return periods.work;
    }

    @Selector([CoreStateSelector.getPeriods])
    static getActivePeriod(periods: CoreStateModel["periods"]) {
        return periods.active;
    }

    @Selector([CoreStateSelector.getPeriods])
    static periodIsChanged(periods: CoreStateModel["periods"]) {
        return periods.changed;
    }

    @Selector([CoreState])
    static getTochnoTime(state: CoreStateModel) {
        return state.currentUser?.tochnoTime;
    }
}
