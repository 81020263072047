import { CommonModule } from "@angular/common";
import { Component, forwardRef, ViewEncapsulation } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";

import { RadioButtonModule } from "primeng/radiobutton";


@Component({
    selector: "ruts-quarter-radio",
    standalone: true,
    imports: [CommonModule, RadioButtonModule, FormsModule],
    templateUrl: "./quarter-radio.component.html",
    styleUrls: ["./quarter-radio.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuarterRadioComponent),
            multi: true,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class QuarterRadioComponent implements ControlValueAccessor {
    quarter: number;
    onTouched: () => void;
    onChange: (x: number) => void;

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    writeValue(obj: number) {
        this.quarter = +obj;
    }

    onQuarterChange(value: number) {
        this.onTouched();
        this.onChange(value);
        this.quarter = value;
    }
}
