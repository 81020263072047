import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

import { Observable } from "rxjs";

import { Select, Store } from "@ngxs/store";

import { PageName } from "@core/enums/defaults.enum";
import { SearchFieldPlaceholderText } from "@core/enums/message.enum";

import { AuthPageActions } from "../../store/auth.actions";
import { AuthPageState } from "../../store/auth.state";

@Component({
    selector: "ruts-auth-invite",
    templateUrl: "./auth-invite.component.html",
    styleUrls: ["./auth-invite.component.scss"],
})
export class AuthInviteComponent implements OnInit {
    public form: UntypedFormGroup;
    public PageName = PageName;
    public readonly placeholder: typeof SearchFieldPlaceholderText = SearchFieldPlaceholderText;

    @Select(AuthPageState.getStatus) isLoading$: Observable<boolean>;
    @Select(AuthPageState.getErrors) errors$: Observable<string[]>;

    constructor(protected _store: Store) {}

    ngOnInit(): void {
        this.form = new UntypedFormGroup({
            invite: new UntypedFormControl(null, [Validators.required]),
        });
    }

    onSubmit() {
        this._store.dispatch(new AuthPageActions.CheckInvite({ code: this.form.value.invite }));
    }
}
